<template>
  <b-button-group>
    <b-button
      v-for="option in options"
      :key="`option_${option.value}`"
      :size="size"
      :variant="value === option.value ? 'primary' : 'white'"
      @click="$emit('input', option.value)"
      :disabled="disabled"
    >
      <span class="text-nowrap">{{ option.text }}</span>
    </b-button>
  </b-button-group>
</template>

<script>
export default {
  name: 'ButtonSelect',
  components: {},
  props: {
    value: {
      type: undefined,
    },
    options: {
      type: Array,
    },
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>
